/* Set the background color of body to tan */
.show-sm {
    display: flex !important;
  }
.show-lg {
    display: none !important;
}

//   /* On screens that are 600px or less, set the background color to olive */
  @media screen and (min-width: 800px) {
    .show-sm {
        display: none !important;
      }
    .show-lg {
        display: flex !important;
    }
    .main-content {
      min-height: 100vh;
      width: calc(100% - 60px);
      margin-left: 60px !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
      
  }