body {
    background-color: #cfd8dc;
}

#loginContainer {
    background-image: url('../assets/images/bg.png');
}

.w-100 {
    width: 100%;
}


/* Input Fields */
.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
    background: #ECECEF;
}
.MuiTextField-root, .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
    border-radius: 10px;
    color: $dark-color !important;
}
.textFieldLight .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
    background: #fff;

}

.MuiFilledInput-underline:before {
    border: 0 !important;
}
.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl::after, 
.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControlt::before {
    border-color: $dark-color !important;
}
.MuiFormLabel-root.Mui-focused {
    color: $dark-color !important;
}

.navigationDrawer {
    position: fixed;
    width: 60px;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 9;
    background: #F4D63A;
    border: 1px solid rgba(220, 186, 12, 0.44);
    box-sizing: border-box;
    /* cRD */

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    transition: all 0.2s;

    .menu-toggle {
        position: absolute;
        bottom: 0;
        height: 59px;
        width: 100%;
    }
    ul{
        display: initial;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            a {
                text-decoration: none;
            }
            a.selected button{
                background-color: rgba(0, 0, 0, 0.1);
            }
            
            button {
                border-radius: 0;
                width: 100%;
                height: 57px;
                opacity: 1;

                .MuiIconButton-label {
                    opacity: 1;
                    display: flex;
                    justify-content: flex-start;

                    img, .img, .material-icons {
                        flex-basis: 34px;
                    }
                    .menu-title {
                        position: absolute;
                        left: 65px;
                    }
                }

               
            }
        }
    }

    .menu-title {
        display: none;
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        align-items: center;
        text-transform: capitalize;
        color: #3A415B;
    }
}
.navigationDrawer.expanded {
    width: 270px;
    // transition: all 0.2s;
    .menu-title {
        display: inline;
    }
}

.bubble-list {
    table tbody tr td, table tbody tr th{
        padding: 4px 16px !important;
    }
    table tbody tr th {
        font-family: $primary-font;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #384354;
    }
}

li button .MuiIconButton-label.MuiIconButton-label {
    opacity: 1;
    color: #384354;
}
.settings-menu-list {
    margin: 0;
    padding: 0;

    .title {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        display: flex;
        padding: 18px;
        align-items: center;
        justify-content: flex-end;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #181D30;

        opacity: 0.5;
    }

    li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        a {
            text-decoration: none;
        }
        a.selected button{
            background-color: $primary-color;
        }

        
        button {
            border-radius: 0;
            width: 100%;
            height: 48px;
            opacity: 1;
            justify-content: flex-end;

            .MuiIconButton-label {
                opacity: 1;
                font-family: $primary-font;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                display: flex;
                align-items: center;
                text-align: right;

            }           
        }
    }
}

.appBar {
    height: 55px;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: white;
    display: flex;
    justify-content: space-around;;
    box-shadow: 0 0 4px rgba(58,65,91,.1), 0 0 12px rgba(0,0,0,.1);
}

.bottomNav {
    height: 55px;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: #F4D63A;
    display: flex;
    justify-content: space-around;;
    box-shadow: 0 0 4px rgba(58,65,91,.1), 0 0 12px rgba(0,0,0,.1);
}

.main-content {
    min-height: 100vh;
    width: 100%;
    padding-bottom: 55px;
    padding-top: 55px;
}

.chartWrapper {
    position: relative;
}

.chartWrapper > canvas {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events:none;
}

.chartAreaWrapper {
    width: 100%;
    overflow-x: scroll;
}