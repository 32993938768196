@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #cfd8dc; }

#loginContainer {
  background-image: url(/static/media/bg.e0c7a804.png); }

.w-100 {
  width: 100%; }

/* Input Fields */
.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
  background: #ECECEF; }

.MuiTextField-root, .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
  border-radius: 10px;
  color: #3A415B !important; }

.textFieldLight .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
  background: #fff; }

.MuiFilledInput-underline:before {
  border: 0 !important; }

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl::after,
.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControlt::before {
  border-color: #3A415B !important; }

.MuiFormLabel-root.Mui-focused {
  color: #3A415B !important; }

.navigationDrawer {
  position: fixed;
  width: 60px;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 9;
  background: #F4D63A;
  border: 1px solid rgba(220, 186, 12, 0.44);
  box-sizing: border-box;
  /* cRD */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  .navigationDrawer .menu-toggle {
    position: absolute;
    bottom: 0;
    height: 59px;
    width: 100%; }
  .navigationDrawer ul {
    display: inline;
    display: initial;
    margin: 0;
    padding: 0;
    width: 100%; }
    .navigationDrawer ul li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .navigationDrawer ul li a {
        text-decoration: none; }
      .navigationDrawer ul li a.selected button {
        background-color: rgba(0, 0, 0, 0.1); }
      .navigationDrawer ul li button {
        border-radius: 0;
        width: 100%;
        height: 57px;
        opacity: 1; }
        .navigationDrawer ul li button .MuiIconButton-label {
          opacity: 1;
          display: flex;
          justify-content: flex-start; }
          .navigationDrawer ul li button .MuiIconButton-label img, .navigationDrawer ul li button .MuiIconButton-label .img, .navigationDrawer ul li button .MuiIconButton-label .material-icons {
            flex-basis: 34px; }
          .navigationDrawer ul li button .MuiIconButton-label .menu-title {
            position: absolute;
            left: 65px; }
  .navigationDrawer .menu-title {
    display: none;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    align-items: center;
    text-transform: capitalize;
    color: #3A415B; }

.navigationDrawer.expanded {
  width: 270px; }
  .navigationDrawer.expanded .menu-title {
    display: inline; }

.bubble-list table tbody tr td, .bubble-list table tbody tr th {
  padding: 4px 16px !important; }

.bubble-list table tbody tr th {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #384354; }

li button .MuiIconButton-label.MuiIconButton-label {
  opacity: 1;
  color: #384354; }

.settings-menu-list {
  margin: 0;
  padding: 0; }
  .settings-menu-list .title {
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    padding: 18px;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #181D30;
    opacity: 0.5; }
  .settings-menu-list li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .settings-menu-list li a {
      text-decoration: none; }
    .settings-menu-list li a.selected button {
      background-color: #F4D63A; }
    .settings-menu-list li button {
      border-radius: 0;
      width: 100%;
      height: 48px;
      opacity: 1;
      justify-content: flex-end; }
      .settings-menu-list li button .MuiIconButton-label {
        opacity: 1;
        font-family: "Roboto Condensed", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: right; }

.appBar {
  height: 55px;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: white;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 4px rgba(58, 65, 91, 0.1), 0 0 12px rgba(0, 0, 0, 0.1); }

.bottomNav {
  height: 55px;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: #F4D63A;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 4px rgba(58, 65, 91, 0.1), 0 0 12px rgba(0, 0, 0, 0.1); }

.main-content {
  min-height: 100vh;
  width: 100%;
  padding-bottom: 55px;
  padding-top: 55px; }

.chartWrapper {
  position: relative; }

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none; }

.chartAreaWrapper {
  width: 100%;
  overflow-x: scroll; }

/* Set the background color of body to tan */
.show-sm {
  display: flex !important; }

.show-lg {
  display: none !important; }

@media screen and (min-width: 800px) {
  .show-sm {
    display: none !important; }
  .show-lg {
    display: flex !important; }
  .main-content {
    min-height: 100vh;
    width: calc(100% - 60px);
    margin-left: 60px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important; } }

